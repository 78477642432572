import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import {Container, Row, Col, Card, ListGroup  } from 'react-bootstrap'

function Mp3List({audioLists,onChange}) {

    function handleChange(key) {
        onChange(key);
    }

    return (
    <ListGroup className='w-100' onSelect={handleChange}>
        {audioLists.map( (mp3,index) => (
            <ListGroup.Item action eventKey={index} active={mp3.active}>{`${mp3.name} - ${mp3.singer}`}</ListGroup.Item>
        ) )}
    </ListGroup>
    )
}

export default Mp3List;