import React, { useState} from 'react'
import ReactDOM from 'react-dom'
import {Container, Row, Col, Card, ListGroup  } from 'react-bootstrap'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import Mp3List from './Mp3List'
import SongerCourusel from './SongerCourusel'
import { YMInitializer } from 'react-yandex-metrika';
import 'react-jinke-music-player/assets/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {

    const audioList = [
    {
      name: 'Поздравление',
      singer: 'Директор компании Стилобит',
      cover:
      'https://b24.stilobit.ru/docs/pub/a63c24df92432ad4af24774d2aa87f7e/download/?&token=mkiluyhomb88',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Congratulation.mp3',
      active: true,
      key: 0,
      paused: false,
    },
    {
      name: 'Jingle Bells',
      singer: 'Helga Weawer',
      cover:
      'https://b24.stilobit.ru/docs/pub/0401189707807ff19e4fbec0514a4a15/showPreview/?&token=zka29bizx7d5',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Jingle Bells.mp3',
      active: false,
      key: 1,
    },
    {
      name: 'Let it snow',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/ba2fe8ad729faa568ea3c415e80131f3/showFile/?&token=hrrqc0qqh2nc',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Let it snow.mp3',
      active: false,
      key: 2,
    },
    {
      name: 'Santa baby',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/b1509fd8c06fa7b62987c158d6333edd/showFile/?&token=nc6qsycunrw0',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Santa Baby.mp3',
      active: false,
      key: 3,
    },
    {
      name: 'Deck the halls',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/abd3b96ac9be2f7489e6a88065a3c1a1/showFile/?&token=u0inwejwyf29',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Deck The Halls.mp3',
      active: false,
      key: 4,
    },
    {
      name: 'Rocking around the Christmas tree',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/c7fae48f766896e2dc0a1e149c9cc383/showFile/?&token=7re0npj7p7gq',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/The Christmas Tree.mp3',
      active: false,
      key: 5,
    },
    {
      name: 'Dream a littie dream',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/0401189707807ff19e4fbec0514a4a15/showPreview/?&token=zka29bizx7d5',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Dream a littie dream.mp3',
      active: false,
      key: 6,
    },
    {
      name: 'Jingle Bell Rock',
      singer: 'Helga Weawer',
      cover:
      'https://b24.stilobit.ru/docs/pub/ba2fe8ad729faa568ea3c415e80131f3/showFile/?&token=hrrqc0qqh2nc',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Jingle Bell Rock.mp3',
      active: false,
      key: 7,
    },
    {
      name: 'Have Youself A Merry Little Christmas',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/b1509fd8c06fa7b62987c158d6333edd/showFile/?&token=nc6qsycunrw0',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Have Youself A Merry Little Christmas.mp3',
      active: false,
      key: 8,
    },
    {
      name: 'Christmas song',
      singer: 'Helga Weawer',
      cover:
        'https://b24.stilobit.ru/docs/pub/abd3b96ac9be2f7489e6a88065a3c1a1/showFile/?&token=u0inwejwyf29',
      musicSrc:
        'https://newyear.stilobit.ru/mp3s/Christmas Song.mp3',
      active: false,
      key: 9,
    },
  ]


  const [audioListState, setAudioList] = useState(audioList);
  const [audioInstance, setAudioInstance] = useState();
  const [updatedKey, setUpdatedKey] = useState(0);

  function changeAudioList(key) {
    var newaudioListState = audioListState.slice()
    newaudioListState.forEach((mp3) => mp3.active = false)
    newaudioListState[key].active = true
    setAudioList(newaudioListState)
    audioInstance.playByIndex(key)
    setUpdatedKey(key)
    //console.log(updatedKey)
  }

  function changeAudioListPlayer(key) {
    var newaudioListState = audioListState.slice()
    newaudioListState.forEach((mp3) => mp3.active = false)
    newaudioListState[key].active = true
    setAudioList(newaudioListState)
    setUpdatedKey(key)
    //console.log(updatedKey)
  }


  const options = {
    audioLists: audioListState,
    theme: 'auto',
    preload: true,
    //remember: true,
    defaultPlayMode: 'order',
    mode: 'full',
    autoPlay: true,
    toggleMode: false,
    showDownload: false,
    showReload: false,
    showLyric: false,
    showDestroy: false,
    autoHiddenCover: true,
    showThemeSwitch: false,
    remove:false,
    spaceBar:true,
    mobileMediaQuery: '(max-width: 1365px)'
  }

  const content = (
    <><h1 className="h1-mobile">с Новым Годом!</h1></>
  )


  return (
    <Container fluid className="h-100 vh-100 d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <Row className='mt-3 mb-3'>
        <Col xs={12}><h1>Компания Стилобит поздравляет Вас с Новым Годом!</h1></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={3}>
          <SongerCourusel  />
        </Col>
        <Col xs={3}>
          <h2>Выберите трек:</h2>
          <Mp3List  audioLists={audioListState} onChange={changeAudioList} key={updatedKey} />
          <h1 className='mt-5'>❄️  ❄️  ❄️  ❄️  ❄️</h1>
        </Col>
        <Col xs={3} className='mt-5'>
          <div className='stilobit w-100'>
          <h2 >В записи этого диска нам помогла замечательная джазовая певица Helga Weaver. Мы надеемся, что её тёплый голос сохранит и приумножит светлое праздничное настроение!</h2>

          <div className='stilobit-right'>
          <h2>Компания "Стилобит"</h2>
          <h2><a href="https://stilobit.ru">stilobit.ru</a></h2>
          </div>

          </div>
        </Col>
        <Col></Col>
      </Row>
      <ReactJkMusicPlayer {...options}
              getAudioInstance={(instance) => {
                setAudioInstance(instance)
              }}
              onPlayIndexChange={(playIndex) => {
                changeAudioListPlayer(playIndex)
              }}
              showMediaSession

      />
      <YMInitializer accounts={[86959826]} />
    </Container>
  )

}

export default App;
