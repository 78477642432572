import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import {Carousel} from 'react-bootstrap'

function SongerCourusel({audioLists}) {

    const songer_photos = [
        'https://b24.stilobit.ru/docs/pub/c7fae48f766896e2dc0a1e149c9cc383/showFile/?&token=7re0npj7p7gq',
        'https://b24.stilobit.ru/docs/pub/0401189707807ff19e4fbec0514a4a15/showPreview/?&token=zka29bizx7d5',
        'https://b24.stilobit.ru/docs/pub/ba2fe8ad729faa568ea3c415e80131f3/showFile/?&token=hrrqc0qqh2nc',
        'https://b24.stilobit.ru/docs/pub/b1509fd8c06fa7b62987c158d6333edd/showFile/?&token=nc6qsycunrw0',
        'https://b24.stilobit.ru/docs/pub/abd3b96ac9be2f7489e6a88065a3c1a1/showFile/?&token=u0inwejwyf29'
    ]

    return (
        <Carousel fade>
            {songer_photos.map((photo) => (
                <Carousel.Item>
                    <img
                    className="d-block w-100 rounded"
                    src={photo}
                    />
                    <Carousel.Caption>
                    <p>Исполнитель: Helga Weawer</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default SongerCourusel;